<template>
  <div class="px-2">
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="5">
        <v-container fluid>
          <span class="font-weight-medium">
            {{ $t('PredictionParameterMax')}}
            <v-tooltip
              bottom
              content-class="faster-tooltip"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4"
                  color="var(--eastman-azure)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
            <span>
              The point at which the dissolution curve levels off.
            </span>
            </v-tooltip>
          </span>
          <v-row class="prediction-row">
            <v-col >
              <v-slider
                thumb-label
                thumb-color="var(--eastman-blue)"
                color="var(--eastman-blue)"
                track-color="var(--eastman-blue-transparent)"
                v-model="aSquared"
                max="100"
                min="30"
                step="1"
              >
                <template v-slot:prepend>
                  <div class="pt-6">
                    <span class="text-body-1">
                      30
                    </span>
                  </div>
                </template>
                <template v-slot:append>
                  <div class="pt-6">
                    <span class="text-body-1">
                      100
                    </span>
                  </div>
                </template>
              </v-slider>
            </v-col>
            <v-col cols="4">
              <v-text-field
                filled
                dense
                suffix="%"
                style="border-radius: 3px"
                type="number"
                v-model="aSquared"
              />
            </v-col>
          </v-row>
          <span class="font-weight-medium">
            {{ $t('PredictionParameterMidpoint')}}
            <v-tooltip
              bottom
              content-class="faster-tooltip"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4"
                  color="var(--eastman-azure)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
            <span>
              The approximate middle of the dissolution. Changing this squeezes or broadens the curve.
            </span>
            </v-tooltip>
          </span>
          <v-row class="prediction-row">
            <v-col cols="8">
              <v-slider
                thumb-label
                thumb-color="var(--eastman-blue)"
                color="var(--eastman-blue)"
                track-color="var(--eastman-blue-transparent)"
                v-model="bSquared"
                max="8.5"
                min="0.1"
                step="0.1"
              >
                <template v-slot:prepend>
                  <div class="pt-6">
                    <span class="text-body-1">
                      0.1
                    </span>
                  </div>
                </template>
                <template v-slot:append>
                  <div class="pt-6">
                    <span class="text-body-1">
                      8.5
                    </span>
                  </div>
                </template>
              </v-slider>
            </v-col>
            <v-col cols="4">
              <v-text-field
                filled
                dense
                suffix="Hrs"
                style="border-radius: 3px"
                type="number"
                v-model="bSquared"
              />
            </v-col>
          </v-row>
          <span class="font-weight-medium">
            {{ $t('PredictionParameterSteepness')}}
            <v-tooltip
              bottom
              content-class="faster-tooltip"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4"
                  color="var(--eastman-azure)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle
                </v-icon>
              </template>
            <span>
              The general steepness of the dissolution curve. Changing this affects how fast the dissolution occurs within a time frame.
            </span>
            </v-tooltip>
          </span>
          <v-row class="prediction-row">
            <v-col cols="8">
              <v-slider
                thumb-label
                thumb-color="var(--eastman-blue)"
                color="var(--eastman-blue)"
                track-color="var(--eastman-blue-transparent)"
                v-model="inputExpC"
                max="100.0"
                min="0.1"
                step="0.1"
              >
                <template v-slot:prepend>
                  <div class="pt-6">
                    <span class="text-body-1">
                      0.01
                    </span>
                  </div>
                </template>
                <template v-slot:append>
                  <div class="pt-6">
                    <span class="text-body-1">
                      100
                    </span>
                  </div>
                </template>
              </v-slider>
            </v-col>
            <v-col cols="4">
              <v-text-field
                filled
                dense
                suffix="%"
                style="border-radius: 3px"
                type="number"
                v-model="inputExpC"
              />
            </v-col>
          </v-row>
          <!-- <span>
            {{ $t('PredictionParameterFlex')}}
          </span>
          <v-row>
            <v-col cols="8">
              <v-slider
                thumb-label
                thumb-color="var(--eastman-blue)"
                color="var(--eastman-blue)"
                track-color="var(--eastman-blue-transparent)"
                v-model="flex"
                max="20"
                min="0"
                step="1"
              >
                <template v-slot:prepend>
                  <div class="pt-6">
                    <span class="text-body-1">
                      0
                    </span>
                  </div>
                </template>
                <template v-slot:append>
                  <div class="pt-6">
                    <span class="text-body-1">
                      20
                    </span>
                  </div>
                </template>
              </v-slider>
            </v-col>
            <v-col cols="4">
              <v-text-field
                filled
                dense
                style="border-radius: 3px"
                type="number"
                suffix="%"
                v-model="flex"
              />
            </v-col>
          </v-row> -->
          <!-- <span>
            {{ $t('PredictionNumRecs')}}
          </span>
          <v-row class="mb-6">
            <v-col cols="12">
              <v-btn-toggle
                v-model="numRecIndex"
                mandatory
              >
                <v-btn
                  v-for="num in numRecChoices"
                  :key="num"
                >
                  {{ num }}
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-checkbox
            v-model="fullSet"
            :label="$t('PredictionExhaustiveSearch')"
          />
          <v-row>
            <v-col cols="12">
              <span>
                {{ $t('ExhaustiveSearchDescription') }}
              </span>
            </v-col>
          </v-row> -->
        </v-container>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="7">
        <v-card
          outlined
          elevation="0"
          class="prediction-subcard"
        >
          <v-card-title class="text-h4 title-break">
            {{ $t('CoatingGraphTargetTitle') }}
          </v-card-title>
          <coatings-chart
            :mainCurve="{ aSquared: this.aSquared, bSquared: this.bSquared, expC: this.expC, flex: this.flex}"
            :showFlex="false"
            :hideToolbar="loading"
          />
        </v-card>
      </v-col>
    </v-row>
    <div
      v-if="dialogDisplay"
    >
      <v-row>
        <v-spacer/>
        <v-col cols="auto">
          <v-btn
            class="mt-8 mr-8"
            text
            large
            style="font-size: 16px;"
            color="var(--eastman-azure)"
            @click="$emit('predictClose')"
          >
            {{ $t('DialogCloseBtnText')}}
          </v-btn>
          <v-btn
            dark
            large
            style="font-size: 16px;"
            color="var(--eastman-azure)"
            @click="predict"
            :loading="loading"
            class="mt-8"
          >
            {{ $t('PredictionButtonText') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div
      v-else
    >
      <v-row>
        <v-col cols="auto">
          <v-btn
            dark
            large
            style="font-size: 16px;"
            color="var(--eastman-azure)"
            @click="predict"
            :loading="loading"
            class="ml-3"
          >
            {{ $t('PredictionButtonText') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- <v-progress-linear
      v-if="loading"
      class="mt-4"
      v-model="fauxProgress"
    /> -->
    <LoadingOverlay
      :showOverlay="loading"
    />
    <ErrorDialog
      :dialog="error"
      title="Error making prediction"
      :message="errorMessage"
      @update:dialog="error = false"
    />
  </div>
</template>

<script>
import predictionAPI from '@/services/PredictionServices'

export default {
  Name: "PredictionForm",
  components: {
    CoatingsChart: () => import('@/components/CoatingsChart.vue'),
    ErrorDialog: () => import('@/components/ErrorDialog.vue'),
    LoadingOverlay: () => import('@/components/LoadingOverlay.vue'),
  },
  props: {
    defaultA: {
      type: Number,
    },
    defaultB: {
      type: Number,
    },
    defaultC: {
      type: Number,
    },
    defaultFlex: {
      type: Number,
    },
    defaultNumRecs: {
      type: Number,
    },
    defaultFullSet: {
      type: Boolean,
    },
    dialogDisplay: {
      type: Boolean,
    },
  },
  data: function () {
    return {
      predictionModes: ['predict curve', 'find parameters'],
      presets: ['Immediate release', 'Extended release', 'Taste Mask'],
      mode: '',
      loading: false,
      inputExpC: 52.0,
      bSquared: 6.7,
      aSquared: 100,
      flex: 10,
      numRecChoices: [1, 2, 3],
      numRecIndex: 0,
      fullSet: true,
      fauxProgress: 0.0,
      interval: 0,
      error: false,
      errorMessage: '',
    }
  },
  computed: {
    numRecs: function () {
      return this.numRecChoices[this.numRecIndex]
    },
    expC: function () {
      var result = (this.inputExpC / 100) * 2
      return result < 0.01 ? 0.01 : result
    },
  },
  methods: {
    predict: async function () {
      if (this.loading) return

      this.loading = true
      this.startFauxLoad()

      // save last used input parameters
      this.$store.dispatch('setCurrentParams', {
        aSquared: this.aSquared,
        bSquared: this.bSquared,
        expC: this.expC,
        flex: this.flex,
        numRecs: this.numRecs,
        fullSet: this.fullSet
      })

      try {
        // get recommendation from API
        var response = await predictionAPI.getRecommendations(this.aSquared, this.bSquared, this.expC, this.flex, this.numRecs, this.fullSet)

        if (!response.error) {
          // console.log(response.data)

          var recs = []
          var maxRecs = response.data.resultsCount

          // for each recommendation returned, add it to the list recs
          for (var i = 0; i < maxRecs; i++) {
            // record warning codes if one is present
            var warning = response.data.in_bound && response.data.in_bound[i]

            var rec = {
              CE: response.data.CE[i],
              CE_perc: response.data.CE_perc[i],
              Coating_perc: response.data.Coating_perc[i],
              warningCode: warning ?? 0,
              graph: null,
            }
            recs.push(rec)
          }
          
          // save the rec list to the store
          this.$store.dispatch('setCurrentRecs', recs)

          // cleanup
          this.loading = false
          this.fauxProgress = 0
          clearInterval(this.interval)

          // send prediction complete signal
          this.$emit('predictComplete')
        } else {
          this.errorMessage = response.error
          this.error = true
          this.fauxProgress = 0
          this.loading = false
          clearInterval(this.interval)
        }
      } catch (error) {
        console.error(error)
        this.errorMessage = 'Please try again in a moment'
        this.error = true
        this.fauxProgress = 0
        this.loading = false
        clearInterval(this.interval)
      }
    },
    startFauxLoad() {
      clearInterval(this.interval)
      var increment
      if (this.fullSet) {
        increment = 1.25
      } else {
        increment = 3
      }

      this.interval = setInterval(() => {
        if (this.fauxProgress < 100) {
          this.fauxProgress += increment
        } else if (this.fauxProgress > 100) {
          this.fauxProgress = 100
        }
      }, 200)
    },
  },
  mounted: function () {
    clearInterval(this.interval)

    if (this.defaultA)
    {
      this.aSquared = this.defaultA
    }
    if (this.defaultB)
    {
      this.bSquared = this.defaultB
    }
    if (this.defaultC)
    {
      this.inputExpC = this.defaultC * 50
    }
    if (this.defaultFlex)
    {
      this.flex = this.defaultFlex
    }
    if (this.defaultNumRecs)
    {
      this.numRecIndex = this.defaultNumRecs - 1
    }
    if (this.defaultFullSet)
    {
      this.fullSet = this.defaultFullSet
    }
  }
}
</script>

<style>
.prediction-subcard {
  padding: 1em;
}

.no-underline {
  border: none !important;
}

.v-input__slot:before {
  border: none !important;
}

.input-unit {
  padding-top: 10px;
}

.half-a-pad {
  padding-top: 3px;
}

.prediction-row {
  margin-bottom: 50px;
}

input {
  text-align: right !important;
  font-size: 21px;
}

.v-text-field__suffix {
  font-size: 21px;
}

.faster-tooltip {
  background-color: #000000e6 !important;
  max-width: 26em;
}
</style>